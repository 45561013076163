import React from 'react'
import { Link } from 'gatsby'

import {
  PlanSpeed,
  PlanType,
  PlanDetails,
  usePlanSuggestion,
} from './components'
import { PlanSuggestionProvider } from './PlanSuggestionContext'
import { Loader } from '../Loader'

export const PlanSuggestion = ({ pageType }) => (
  <PlanSuggestionProvider pageType={pageType}>
    <ContainerContent />
  </PlanSuggestionProvider>
)

const ContainerContent = () => {
  const { loading, pageType } = usePlanSuggestion()

  return (
    <div className="showcase-plans" id="plans">
      <div className="container">
        <Loader loading={loading} />
        <h2 className="banner-title text-white">
          Choose the plan that's right for you
        </h2>
        <section className="plan-table">
          <div className="plan-wrapper row">
            <div className="offset-md-2" />
            <PlanType />
            <PlanSpeed />
          </div>
          <PlanDetails />
        </section>
        {pageType === 'mobile' && (
          <section className="sim-activation-call-to-action plan-suggestion-activate">
            <h3>Got your Accord SIM and you're ready to go?</h3>
            <Link
              className="btn btn-lg btn-accord btn-orange"
              to="/sim-activation"
            >
              Activate my SIM
            </Link>
          </section>
        )}
      </div>
    </div>
  )
}
