import React from 'react'

export const CallToActionNbn = () => (
  <section className="call-to-action-mobile">
    <div className="container">
      <h2>We're here to help</h2>
      <p>
        <strong>COVID-19 Financial Assistance:</strong> We're supporting our
        customers who are impacted by COVID-19, including financial assistance.
        If you are impacted, please contact us on 1300 017 150 Mon-Fri between
        9am-5pm AEST.
      </p>
      <br></br>
      <p>
        {' '}
        <strong>COVID-19 Connection Assistance: </strong>We're supporting
        families with school age children needing to access online learning. If
        you are impacted, please contact us on 1300 017 150 Mon-Fri between
        9am-5pm AEST.
      </p>
    </div>
  </section>
)
