import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { OutageNews } from '../components/shared'

import '../assets/scss/index.scss'
import livechat from '../services/livechat'

export const Layout = props => {
  useEffect(() => {
    livechat()
  }, [])

  const { children } = props

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div className="flexbox-frame">
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[{ name: 'description', content: 'Accord by Officeworks' }]}
          >
            <link
              rel="apple-touch-icon-precomposed"
              sizes="57x57"
              href="/icons/apple-touch-icon-57x57.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="114x114"
              href="/icons/apple-touch-icon-114x114.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="72x72"
              href="/icons/apple-touch-icon-72x72.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="144x144"
              href="/icons/apple-touch-icon-144x144.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="60x60"
              href="/icons/apple-touch-icon-60x60.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="120x120"
              href="/icons/apple-touch-icon-120x120.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="76x76"
              href="/icons/apple-touch-icon-76x76.png"
            />
            <link
              rel="apple-touch-icon-precomposed"
              sizes="152x152"
              href="/icons/apple-touch-icon-152x152.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/favicon-196x196.png"
              sizes="196x196"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/favicon-96x96.png"
              sizes="96x96"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/favicon-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/favicon-16x16.png"
              sizes="16x16"
            />
            <link
              rel="icon"
              type="image/png"
              href="/icons/favicon-128.png"
              sizes="128x128"
            />
            <meta name="application-name" content="Accord By Officeworks" />
            <meta name="msapplication-TileColor" content="#FFFFFF" />
            <meta name="msapplication-TileImage" content="mstile-144x144.png" />
            <meta
              name="msapplication-square70x70logo"
              content="mstile-70x70.png"
            />
            <meta
              name="msapplication-square150x150logo"
              content="mstile-150x150.png"
            />
            <meta
              name="msapplication-wide310x150logo"
              content="mstile-310x150.png"
            />
            <meta
              name="msapplication-square310x310logo"
              content="mstile-310x310.png"
            />
            <meta
              http-equiv="Content-Security-Policy"
              content="upgrade-insecure-requests"
            />
          </Helmet>
          <div className="main-content">
            <Header />
            {children}
          </div>
          <OutageNews />
          <Footer />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
