import React from 'react'
import PropTypes from 'prop-types'
import { Link as ScrollLink } from 'react-scroll'
import { Link } from 'gatsby'

import accordTitle from '../../assets/images/accord-title.svg'
import accordMobileLogo from '../../assets/images/accord-mobile-logo.svg'
import accordNbnLogo from '../../assets/images/accord-nbn-logo.svg'

import otBannerSmile from '../../assets/images/ot-banner-smile.svg'
import iconSpeechBubbleGreen from '../../assets/images/icon-speech-bubble-green.svg'
import iconMobileWhite from '../../assets/images/icon-mobile-white.svg'

const BannerLogo = ({ variation = 1, image }) => (
  <img
    src={image}
    alt="Accord with Officeworks"
    className="accord-title"
    width={variation === 2 ? '450' : ''}
  />
)

const StartHereButton = ({ variation = 1, label, url }) => (
  <button
    className={`btn btn-xl btn-accord ${variation === 3 ? 'btn-orange' : ''}`}
    onClick={() => (window.location.href = url)}
  >
    {label}
  </button>
)

export const HeroBanner = ({ variation = 1 }) => (
  <>
    <section className={`banner banner-${variation}`}>
      <div className="banner-image-container" />
      <div className="container">
        <div className="row reverse-mobile">
          <div className="col-md-6 text-center text-md-left">
            {
              {
                1: (
                  <>
                    <BannerLogo image={accordNbnLogo} variation={variation} />
                    <p className="tagline">
                      Unlimited nbn™ plans <br /> starting from $60
                    </p>
                    {/* <StartHereButton
                      variation={variation}
                      label="Start here"
                      url="/sign-up"
                    /> */}
                  </>
                ),
                2: (
                  <>
                    <BannerLogo image={accordTitle} variation={variation} />
                    <p className="tagline">
                      A great range of nbn™ and mobile phone plans to help you
                      stay connected
                    </p>
                  </>
                ),
                3: (
                  <>
                    <BannerLogo
                      image={accordMobileLogo}
                      variation={variation}
                    />
                    <p className="tagline">
                      SIM only mobile plans starting from $15 per month
                    </p>
                    <ScrollLink
                      tabIndex=""
                      to="plans"
                      smooth={true}
                      duration={600}
                      className="btn btn-xl btn-accord btn-orange"
                    >
                      Choose plan
                    </ScrollLink>
                    <StartHereButton
                      variation={variation}
                      label="Activate SIM"
                      url="/sim-activation"
                    />
                  </>
                ),
                4: (
                  <>
                    <BannerLogo
                      image={accordMobileLogo}
                      variation={variation}
                    />
                    <p className="tagline">
                      SIM only mobile plans starting from $15 per month
                    </p>
                    <ScrollLink
                      tabIndex=""
                      to="plans"
                      smooth={true}
                      duration={600}
                      className="btn btn-xl btn-accord btn-orange"
                    >
                      Choose plan
                    </ScrollLink>
                  </>
                ),
              }[variation]
            }
          </div>
          {
            {
              1: (
                <div className="col-md-6">
                  <img
                    src={otBannerSmile}
                    alt="icon Smile"
                    width="48%"
                    className="banner-plans"
                  />
                </div>
              ),
              2: (
                <div className="col-md-6 v-centre">
                  <img
                    src={iconSpeechBubbleGreen}
                    alt="icon speech bubbles"
                    width="50%"
                    className="banner-home"
                  />
                </div>
              ),
              3: (
                <div className="col-md-6">
                  <img
                    src={iconMobileWhite}
                    alt="icon mobile"
                    className="banner-plans"
                    width="30%"
                  />
                </div>
              ),
              4: (
                <div className="col-md-6">
                  <img
                    src={iconMobileWhite}
                    alt="icon mobile"
                    className="banner-plans"
                    width="30%"
                  />
                </div>
              ),
            }[variation]
          }
        </div>
      </div>
    </section>

    {variation === 4 && (
      <section className="sim-activation-call-to-action">
        <h2>Got your Accord SIM and you're ready to go?</h2>
        <Link className="btn btn-lg btn-accord btn-orange" to="/sim-activation">
          Activate my SIM
        </Link>
      </section>
    )}
  </>
)

HeroBanner.propTypes = {
  variation: PropTypes.number,
}
