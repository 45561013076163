module.exports = [{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-remark-images/3.1.25_82878940f19774a3cf3db21c1ffc6ad1/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1140},
    },{
      plugin: require('/solution/node_modules/.registry.npmjs.org/gatsby-plugin-google-analytics/2.1.20_7fe8e194c5ece6624c05cb4f19ed6d3d/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132209813-1"},
    },{
      plugin: require('/solution/ANEX.Website.Osu/OT/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
