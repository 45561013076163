import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import headerLogo from '../../assets/images/accord-logo.svg'

export const Header = () => {
  const [state, setstate] = useState({
    slide: 0,
    ossUrl: '',
  })

  useEffect(() => {
    setstate({
      ...state,
      ossUrl: window['OSS_URL'],
    })
  }, [])

  const { ossUrl } = state

  return (
    <header className="navbar navbar-default">
      <nav className="container-fluid" role="navigation">
        <div className="navbar-header">
          <Link to="/" className="navbar-brand">
            <img
              src={headerLogo}
              alt="Accord Broadband Logo"
              width="131"
              height="40"
            />
          </Link>
        </div>
        <ul className="nav navbar-nav navbar-right">
          <li>
            <Link to="/contact" className="btn_phone fw-600">
              Contact us
            </Link>
          </li>
          <li>
            <a className="btn btn_my-account" href={ossUrl}>
              My Account
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}
