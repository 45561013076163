import React from 'react'

export const generalFaqData = [
  {
    question: 'How can I securely access My Account?',
    answer: (
      <>
        <p>
          Please log on to{' '}
          <a href="https://myaccount.accordconnect.com.au">
            www.myaccount.accordconnect.com.au
          </a>{' '}
          and enter your Accord account email address.
        </p>
        <p>
          Each time you try to access your online account, we will send a unique
          single-use access code to your nominated email address. You cannot
          access My Account without a valid code.
        </p>
      </>
    ),
  },
  {
    question: 'How can I update my contact information?',
    answer: (
      <p>
        Please go to the Profile section in My Account to view and edit your
        personal details.
      </p>
    ),
  },
  {
    question: 'How can I check my contract information or change my plan?',
    answer: (
      <>
        <p>
          Please go to the Plan section in My Account to view your contract
          information or change your current plan.
        </p>
        <p>
          Plan changes will take effect at the start of your next billing cycle.
        </p>
      </>
    ),
  },
  {
    question: 'How can I manage my usage online?',
    answer: (
      <>
        <p>Please go to the Usage section of My Account to view your usage.</p>
        <p>
          You can filter your usage by day, by month or by year. You can also
          check your total usage and your average daily usage for the month.
        </p>
      </>
    ),
  },
  {
    question:
      'How can I view and download a detailed invoice from the Invoice Summary email I received?',
    answer: (
      <>
        <p>
          Please go to the Billing section of My Account to download a detailed
          invoice.
        </p>
        <p>
          You can also view your total balance, pay now, update payment details
          and check the last 12 months billing history.
        </p>
      </>
    ),
  },
]

export const mobileFaqData = [
  {
    question: 'Should I install a SIM card when the device is on?',
    answer: (
      <>
        <p>
          Do not install a SIM card when the device is on, otherwise your SIM
          card may be damaged. Turn the power off to your device before
          inserting a SIM card.
        </p>
      </>
    ),
  },
  {
    question: 'How do I power down my device?',
    answer: (
      <>
        <p>
          For iPhone’s or other smart phones, hold down the power button until
          the slide to power off appears on the screen and then slide the switch
          until the device powers off.
        </p>
        <p>
          If this doesn’t work for your phone then please refer to your
          manufacturer instructions.
        </p>
      </>
    ),
  },
  {
    question: 'How do I locate the SIM card slot on my device?',
    answer: (
      <>
        <p>
          The precise location of the SIM card slot will vary depending on
          whether it is located within the battery compartment, the device side
          port or in a SIM card tray.
        </p>
      </>
    ),
  },
  {
    question: 'Which way up should a SIM card go?',
    answer: (
      <>
        <p>
          Insert the SIM card based on the indention on the card and the angled
          cut-off on the device.
        </p>
      </>
    ),
  },
  {
    question: 'Which side of SIM faces up?',
    answer: (
      <>
        <p>Insert the SIM card with the metal contacts facing down.</p>
      </>
    ),
  },
  {
    question: 'How do I insert a SIM card within the battery compartment?',
    answer: (
      <>
        <ul>
          <li>Remove the phone’s back cover.</li>
          <li>Lift and remove the battery up and out of the phone.</li>
          <li>Locate the SIM slot.</li>
          <li>Insert the SIM card into the SIM slot.</li>
          <li>Snap the back case of the device back into place. </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How do I insert a SIM card to my device’s side port?',
    answer: (
      <>
        <ul>
          <li>Open the access cover on the side of the device.</li>
          <li>
            To insert, push the SIM card into slot then close latch to keep it
            in place.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How do I eject the SIM card tray?',
    answer: (
      <>
        <ul>
          <li>
            To remove, push a paper clip or SIM eject tool into the circular
            opening on the SIM card tray to pop it open.
          </li>
          <li>To insert, push the tray back into the slot.</li>
          <li>
            Insert the card tray completely and in the same orientation that you
            removed it. The tray will only insert one way.
          </li>
          <li>
            Make sure the tray is all the way back in the device before
            continuing.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How do I know which SIM size is right for my device?',
    answer: (
      <>
        <ul>
          <li>
            All SIM cards come triple punched with these sizes available:
            Standard SIM, Micro SIM and Nano SIM.
          </li>
          <li>
            Depending on which device you use, you will need to choose between
            either of these 3 sizes. If you are unsure, compare the SIM card
            slot size to the SIM card provided to determine the right size. This
            will make it easier to recognise and ensure you pop out the right
            SIM size for your device.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'How do I power on my device?',
    answer: (
      <>
        <p>
          Hold down the power button. Doing so will turn your device back on.
          Your device should connect to its new network automatically, though
          you may first be prompted to activate it.
        </p>
      </>
    ),
  },
  {
    question:
      'I am alerted of "No SIM Card inserted", "Invalid SIM", or "SIM Card cannot be detected". What should I do?',
    answer: (
      <>
        <ul>
          <li>
            Your device may be configured from your previous mobile provider and
            will need to be reconfigured.
          </li>
          <li>Restart the device.</li>
          <li>Reinsert the SIM card to ensure that it is properly inserted.</li>
          <li>Check if the SIM card is damaged.</li>
        </ul>
      </>
    ),
  },
]

export const nbnFaqData = [
  {
    question: 'What is nbn?',
    answer: (
      <>
        <p>
          The National Broadband Network (nbn) is an Australian national network
          project being rolled out and operated by NBN Co, an Australian
          Government agency. Internet Service Providers (ISPs) including Accord
          contract with nbn to access the network and sell internet access to
          end users.
        </p>
        <p>The nbn is owned, operated, and maintained by NBN Co.</p>
      </>
    ),
  },
  {
    question: 'Do I need to switch to the nbn?',
    answer: (
      <>
        <p>
          Yes. If you are in an area where the nbn rollout has completed you
          will need to switch to the nbn before the copper infrastructure in
          your area is decommissioned. This typically occurs within 18 months
          from when nbn was available in the area.
        </p>
      </>
    ),
  },
  {
    question: 'When can I sign up for an Accord service?',
    answer: (
      <p>
        You can see if Accord nbn is available at your address at{' '}
        <a href="https://www.accordconnect.com.au">www.accordconnect.com.au</a>
      </p>
    ),
  },
  {
    question: 'What are the Accord nbn plans?',
    answer: (
      <p>
        Check out the Accord nbn plans at{' '}
        <a href="https://www.accordconnect.com.au">www.accordconnect.com.au</a>
      </p>
    ),
  },
  {
    question: 'Can I get an Accord voice service?',
    answer: (
      <p>
        Yes. You can add a Voice over Internet Protocol (VoIP) service to your
        Accord nbn plan during signup or after activation. For more information,
        please see{' '}
        <a href="https://www.accordconnect.com.au">www.accordconnect.com.au</a>
      </p>
    ),
  },
  {
    question: 'What are the Accord voice plans?',
    answer: (
      <p>
        Accord voice plans can be added to any Accord nbn service. For more
        information, please see{' '}
        <a href="www.accordconnect.com.au">www.accordconnect.com.au</a>
      </p>
    ),
  },
  {
    question:
      'What are the contract terms when signing-up with Accord nbn and voice plans?',
    answer: (
      <p>
        Accord nbn and voice plans are offered with a 12-month contract. Please
        refer to the Critical Information Summary and Standard Form of Agreement
        available on the Accord website for more information.
      </p>
    ),
  },
  {
    question:
      'I already have nbn with another provider.  Can I get nbn from Accord?',
    answer: (
      <p>
        Yes. Once you have signed up with Accord, you will need to cancel your
        existing service with the other provider (cancellation charges may apply
        with your other provider).
      </p>
    ),
  },
  {
    question: 'Can I change my nbn plan once I have signed up?',
    answer: (
      <>
        <p>
          Yes. You can upgrade your plan at any time from Standard to Fast or
          Ultra Fast (depending on service availability in your area) using the
          Accord My Account portal. You will be charged the higher monthly rate
          applicable to your new plan once the changeover takes effect.
        </p>
        <p>
          You can also downgrade your plan at any time to Fast or Standard.
          Charges may apply for downgrading your plan during your contract
          period.
        </p>
      </>
    ),
  },
  {
    question:
      'Once I upgrade to an nbn service, can I go back to an ADSL service?',
    answer: (
      <>
        <p>
          No. You cannot revert to an ADSL service once it has been migrated to
          nbn.
        </p>
      </>
    ),
  },
  {
    question: 'I am renting - what should I do?',
    answer: (
      <>
        <p>
          The premise owner needs to agree to the installation of the nbn
          equipment (if required) and its placement.
        </p>
        <p>
          You should inform the premise owner of the nbn technician appointment
          time so they can be present or available by phone should any questions
          arise regarding the placement of nbn equipment.
        </p>
      </>
    ),
  },
  {
    question: 'Is nbn reliable compared to ADSL broadband?',
    answer: (
      <>
        <p>
          nbn service is delivered using fibre optic technology, which is
          generally more reliable than a standard ADSL broadband service. Fibre
          optic technology is less affected by distance, weather conditions,
          radio and electromagnetic interference.
        </p>
      </>
    ),
  },
  {
    question: 'Can I have more than one nbn connection at a specific address?',
    answer: (
      <>
        <p>
          Yes. It is possible to have more than one connection if the address is
          using Fibre to the Premise (FTTP) technology or there are multiple
          phone cables on premise for Fibre to the Building (FTTB) and Fibre to
          the Node (FTTN).
        </p>
      </>
    ),
  },
  {
    question: 'Can I change nbn technology from HFC to FTTP or FTTN to FTTP?',
    answer: (
      <>
        <p>
          No. nbn delivery technologies are predetermined for your address by
          NBN co.
        </p>
      </>
    ),
  },
  {
    question:
      'Can I use my existing analogue phone with an Accord Voice service?',
    answer: (
      <>
        <p>
          All modems supplied by Accord come with an inbuilt VoIP adapter that
          allows you to use your existing analogue phone.
        </p>
        <p>
          If you already have an nbn-compatible modem you will need to supply
          your own adapter or upgrade to a VoIP handset.
        </p>
      </>
    ),
  },
  {
    question: 'Can you offer medical priority assistance for voice services?',
    answer: (
      <>
        <p>
          No. The voice service offered by Accord is not suitable for customers
          requiring medical priority assistance. You should consult a medical
          practitioner for alternative options.
        </p>
      </>
    ),
  },
  {
    question:
      'What happens to my existing phone service after nbn installation?',
    answer: (
      <>
        <p>
          You will need to disconnect your existing phone service by contacting
          your phone provider.
        </p>
        <p>
          You can add an Accord Voice over Internet Protocol (VoIP) service to
          your nbn plan during signup or after activation. If you add VoIP
          during signup, Accord can transfer your existing phone number to your
          new service.
        </p>
      </>
    ),
  },
  {
    question: 'Does Accord offer battery backup?',
    answer: (
      <>
        <p>
          No. In the event of a power outage, services that rely on the internet
          will be disrupted (i.e. CCTV, back to base alarms etc.) During a power
          outage, you will be unable to make or receive any phone calls, which
          also includes contacting emergency services.
        </p>
      </>
    ),
  },
  {
    question:
      'Will the Accord voice service work in the event of a power outage?',
    answer: (
      <>
        <p>
          No. All nbn VoIP services require the internet to operate and will not
          work if there is a power outage. You should ensure that you have
          another method of making telephone calls in case of emergency.
        </p>
      </>
    ),
  },
  {
    question: 'What is an nbn New Development Fee?',
    answer: (
      <>
        <p>
          A New Development Fee is a one-time charge of $300 (including GST)
          introduced by nbn on April 2016 for all new service orders in areas
          identified within the boundary of a new development.
        </p>
      </>
    ),
  },
  {
    question:
      'If I have already paid the New Development Fee to connect an nbn service to my current address, then I move to a new address that does not have an active nbn service, will I need to pay the New Development Fee again?',
    answer: (
      <>
        <p>
          Yes. A New Development Fee will be charged as a once off per premise
          address identified within the boundary of a new development.
        </p>
      </>
    ),
  },
  {
    question:
      'Will an Early Termination Fee apply if I cancel my Accord nbn service before the end of my contract period?',
    answer: (
      <>
        <p>
          Yes. The Early Termination Fee for cancelling your service before the
          end of the contract period is $110 (including GST). Please refer to
          the Standard Form of Agreement for your service for more information.
        </p>
      </>
    ),
  },
  {
    question:
      'Are there any additional fees when ordering a second nbn service?',
    answer: (
      <>
        <p>
          Additional fees only apply to Fibre to the Building (FTTB) and Fibre
          to the Node (FTTN) if the premise only has a single phone cable. This
          is also known as a Subsequent Installation Fee.
        </p>
      </>
    ),
  },
  {
    question: 'Can I use my existing modem to connect to the nbn service?',
    answer: (
      <>
        <p>
          You should check whether your modem manufacturer to determine if your
          existing modem can connect to the nbn service.
        </p>
      </>
    ),
  },
  {
    question: 'Which modem should I choose?',
    answer: (
      <>
        <p>
          The TP-Link VN020 is great for light internet use and features Wi-Fi
          suitable for small households with less than 4 users.
        </p>
        <p>
          The TP Link VR1600V modem features AC1600 Wi-Fi and is suitable for
          households and businesses with 4-5 users.
        </p>
      </>
    ),
  },
  {
    question: 'How long is the warranty for my modem?',
    answer: (
      <>
        <p>
          The TP Link VR1600V and TP Link VN020 modems come with a 36-month
          manufacturer warranty.
        </p>
      </>
    ),
  },
  {
    question:
      'Can I request the modem be delivered to a different address to my installation address?',
    answer: (
      <>
        <p>
          Yes. You can choose an alternate delivery address for the modem when
          you sign up.
        </p>
      </>
    ),
  },
  {
    question: 'Can I track the delivery of my modem?',
    answer: (
      <>
        <p>
          Yes. Your modem delivery can be tracked online using the StarTrack
          tracking reference provided once the hardware has been dispatched.
        </p>
        <p>
          <a href="https://startrack.com.au">https: //startrack.com.au</a>.
          StarTrack tracking numbers can take up to 24 hours for registration.
        </p>
      </>
    ),
  },
  {
    question: 'Can I pick up the modem instead of having it shipped?',
    answer: (
      <>
        <p>
          No. The modem is shipped to your nominated delivery address following
          signup. If you miss the delivery, you can collect your modem from the
          Licensed Post Office (LPO) nominated on your missed delivery
          notification.
        </p>
      </>
    ),
  },
  {
    question: 'If I cancel my nbn service, do I need to return the modem?',
    answer: (
      <>
        <p>
          No. You can keep the modem that was purchased during signup as you
          have already paid for it in full.
        </p>
      </>
    ),
  },
  {
    question: 'How long does it take to get connected to Accord nbn?',
    answer: (
      <>
        <p>
          We will always keep you informed about your expected nbn connection
          date. If you have any questions about your Accord nbn sevice, please
          call 1300 017 150.
        </p>
        <p>
          Depending on the nbn technology available in your area an nbn
          technician may be required to attend onsite to install necessary
          equipment. If this is required, your connection may take up to 20
          business days to complete from the time your order is confirmed.
        </p>
        <p>
          If no technician is required at your service address, your connection
          will generally be completed within 3 business days of your order.
        </p>
      </>
    ),
  },
  {
    question:
      'Is a technician appointment required to install an Accord nbn service?',
    answer: (
      <>
        <p>
          Depending on the nbn technology available in your area an nbn
          technician may be required to attend onsite to install necessary
          equipment.
        </p>
        <p>
          We will advise if a technician appointment is required once you
          complete the signup process and schedule the appointment on your
          behalf.
        </p>
      </>
    ),
  },
  {
    question: 'Can I request a specific technician appointment date?',
    answer: (
      <>
        <p>
          You can request a specific technician appointment date by calling 1300
          017 150, however we are unable to guarantee that your requested date
          will be available. Once an appointment has been requested, it goes
          through a series of qualifications. A confirmation of the date is
          provided within 24 hours.
        </p>
        <p>
          Please note that requesting a specific technician appointment date may
          result in your connection taking longer than normal.
        </p>
      </>
    ),
  },
  {
    question:
      'What should I do if I am unavailable on the technician appointment date?',
    answer: (
      <>
        <p>If you can’t be available on your scheduled appointment date:</p>
        <ul>
          <li>
            You can request to reschedule your appointment to a different date
            by calling 1300 017 150. Your request must be made at least 3 full
            business days prior to the scheduled appointment.{' '}
          </li>
          <li>
            Alternatively, any person 18 years or above can be present at the
            address during the technician appointment.
          </li>
        </ul>
      </>
    ),
  },
  {
    question: 'What happens if I miss the nbn technician appointment?',
    answer: (
      <>
        <p>
          An incorrect call out fee of $220 (including GST) may be applied. The
          appointment will be rescheduled to the next available date and you
          will be notified using your preferred communication method (email or
          SMS).
        </p>
      </>
    ),
  },
  {
    question: 'Who can request for an appointment date change?',
    answer: (
      <>
        <p>
          The primary account holder or authorised person can request the
          appointment date change.
        </p>
      </>
    ),
  },
  {
    question:
      'Once the appointment is done, can I immediately use the nbn service?',
    answer: (
      <>
        <p>
          Not yet. You will receive an email or SMS notification depending on
          the preferred contact method once your service is ready to use.
        </p>
      </>
    ),
  },
  {
    question: 'How long is the nbn installation process?',
    answer: (
      <>
        <p>
          The installation process may take up to 20 business days depending on
          the type of nbn installation required at your installation address and
          if required, the availability of an nbn technician. We will send
          notifications to your preferred contact method (email or SMS) to keep
          you up to date during the process.
        </p>
      </>
    ),
  },
  {
    question: 'How can I keep track of my connection progress?',
    answer: (
      <>
        <p>
          You can select your preferred contact method (email or SMS) during
          signup. We will send you email or SMS notifications during the
          connection process to keep you up to date.
        </p>
      </>
    ),
  },
  {
    question: 'Will Accord keep me updated during the application process?',
    answer: (
      <>
        <p>
          Yes. We will keep you informed on the progress of your service
          connection and expected activation date via email and SMS.
        </p>
      </>
    ),
  },
  {
    question: 'Do I need to deal directly with NBN Co?',
    answer: (
      <>
        <p>
          No. We will schedule an nbn technician appointment for you if it is
          required to connect your service. NBN Co may contact you before your
          appointment to arrange any required preliminary work.{' '}
        </p>
      </>
    ),
  },
  {
    question:
      'What if I did not receive a notification about when my Accord nbn will be connected?',
    answer: (
      <>
        <p>
          Please call 1300 017 150 or email support@accordconnect.com.au for an
          update on when your service will be connected.
        </p>
      </>
    ),
  },
  {
    question:
      'Once the nbn service is activated, are there any additional instructions required to use the nbn?',
    answer: (
      <>
        <p>
          You will need to connect your modem to the nbn connection point inside
          your home or office before you can access the internet.
        </p>
        <p>
          If you purchased a modem when you selected your Accord nbn plan, just
          plug it in and your internet will be ready to use.
        </p>
        <p>
          If you are using your own modem, please follow the instructions in
          your Accord nbn Welcome Pack to configure your modem.
        </p>
      </>
    ),
  },
  {
    question: 'What happens if I move home or office?',
    answer: (
      <>
        <p>
          In most cases you will be able to transfer your nbn service to your
          new address (a fee may apply). Please call 1300 017 150 for more
          information.{' '}
        </p>
      </>
    ),
  },
  {
    question: 'When does my contract start?',
    answer: (
      <>
        <p>
          Your contract start date commences once your service has been
          activated and is ready for use. You will receive an email or SMS when
          your service has been activated.
        </p>
      </>
    ),
  },
  {
    question: 'Why is my nbn service charged in advance?',
    answer: (
      <>
        <p>
          Accord nbn is a prepaid service requiring payment in advance on a
          monthly basis. If you have added an Accord Voice service to your plan,
          any usage outside your plan allowance is charged in arrears.
        </p>
      </>
    ),
  },
  {
    question: 'Do charges include GST? ',
    answer: (
      <>
        <p>Yes. All charges are inclusive of GST.</p>
      </>
    ),
  },
  {
    question: 'When do I receive my bill?',
    answer: (
      <>
        <p>
          You will receive a bill summary email on the monthly anniversary of
          the service activation date. The email provides a link for you to
          access the Accord My Account Portal where you can download your
          detailed .PDF invoice.
        </p>
      </>
    ),
  },
  {
    question: 'Will I receive paper bills monthly?',
    answer: (
      <>
        <p>
          No. All bills are sent by email. Bills can also be accessed through
          the Accord My Account Portal.
        </p>
      </>
    ),
  },
  {
    question: 'How do I pay a bill?',
    answer: (
      <>
        <p>
          Your bill will be paid automatically via direct debit using your
          nominated credit card or Officeworks 30 Day Business Account. To
          update payment details, please call 1300 017 150.
        </p>
      </>
    ),
  },
  {
    question: 'Does Accord have other payment options?',
    answer: (
      <>
        <p>
          Yes. You can also use your Officeworks 30 Day Business Account as a
          payment method (subject to the Terms and Conditions of the 30 Day
          Business Account).
        </p>
      </>
    ),
  },
  {
    question: 'Why is my account being suspended?',
    answer: (
      <>
        <p>
          An account suspension generally happens when we have been unable to
          process payment using your selected method after multiple attempts.
        </p>
        <p>
          To avoid an account suspension, please ensure your credit card (or
          Officeworks 30 Day Business Account) details are up to date and
          sufficient funds are available to process a payment prior to your
          bill’s monthly due date.
        </p>
        <p>For any billing queries, please call 1300 017 150.</p>
      </>
    ),
  },
]
