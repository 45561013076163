import React from 'react'

export const PlanOption = ({ option, index, changeOption, currentIndex }) => (
  <div
    className={`plan-option ${currentIndex == index && 'selected'}`}
    onClick={() => changeOption(index)}
    style={{
      display: 'flex',
    }}
  >
    <label
      className="radio-button"
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        alignItems: 'center',
      }}
    >
      {option}
      <input
        name={option}
        type={'radio'}
        value={index}
        onChange={() => changeOption(index)}
        checked={currentIndex == index}
      />
      <small className="label"></small>
    </label>
  </div>
)
