import React from 'react'
import useFetchOutages from '../../services/useFetchOutages'

export const OutageNews = () => {
  const content = useFetchOutages()
  return (
    <div className="container">
      <div className="news-container">
        <div className="news-item">
          <div className="news-title">Outages</div>
          <div className="news-message">
            {content.length > 0 ? (
              <div dangerouslySetInnerHTML={{ __html: content[0].content }} />
            ) : (
              <p>No outage available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
