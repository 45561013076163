import React, { useEffect } from 'react'

import helper from '../services/helper'
import { Layout } from '../layouts/Layout'
import { HeroBanner, Showcase, SEO } from '../components/shared'
// import { AddressButton } from '../components/nbn'
import { PlanSuggestion } from '../components/shared/plan-suggestion'
import { CallToActionNbn } from '../components/nbn'

const ProductNbn = () => {
  useEffect(() => {
    if (window.innerWidth > 991) {
      helper.syncHeights('.showcase-item h3')
    }
  }, [])

  return (
    <Layout>
      <SEO
        title={'Accord NBN'}
        description="Unlimited nbn™ plans starting from $60"
      />
      <main id="page-product-nbn">
        <HeroBanner />

        {/* <AddressButton /> */}

        <Showcase />

        <PlanSuggestion pageType="nbn" />

        <CallToActionNbn />
      </main>
    </Layout>
  )
}

export default ProductNbn
