import React from 'react'

import { PlanOption } from './PlanOption'
import { usePlanSuggestion } from './usePlanSuggestion'

export const PlanType = () => {
  const {
    currentSelectedPlan,
    currentTypeIndex,
    types,
    setType,
  } = usePlanSuggestion()

  return (
    <div className="col-md-4">
      {currentSelectedPlan() && (
        <div className="plan">
          <div className="plan-header" style={{ minHeight: '98px' }}>
            <h3>Plan type</h3>
          </div>
          <div className="plan-body">
            {Object.keys(types).map(type => (
              <PlanOption
                option={types[type]}
                changeOption={setType}
                currentIndex={currentTypeIndex}
                index={type}
                key={type}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
