import React from 'react'
import { Layout } from '../layouts/Layout'
import useFetchOutages from '../services/useFetchOutages'

const Outages = () => {
  const content = useFetchOutages()

  return (
    <Layout>
      <div className="outage-banner">
        <div className="p-4">
          <h1 className="banner-title text-white">Major Outages</h1>
        </div>
      </div>
      <section className="outage text-dark">
        <div className="container">
          <div className="col">
            <div className="container mt-3">
              <p className="mt-3">
                The status of our network and that of our wholesalers is shown
                below.
              </p>
            </div>

            <div className="container mt-5">
              {content.map((block, index) => (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: block.content }}
                />
              ))}
            </div>

            <div className="container mt-5 mb-5">
              <p>
                For further information on how we report major outages, please
                refer to our{' '}
                <a
                  href="/pdf/Accord-Communication-of-Major-Outages.pdf"
                  target="_blank"
                >
                  Major Outage Communications Procedure
                </a>
                .
              </p>
              <p className="mt-4 font-weight-bold">Our Wholesalers</p>
              <p className="mt-3">
                For information on outages affecting the nbn®, Opticomm, or our
                mobile customers, please visit the links below:
              </p>
              <ul className="mt-3">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.nbnco.com.au/support/network-status"
                  >
                    nbn® outages
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://online.telco.opticomm.com.au/network"
                  >
                    Opticomm outages
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.optus.com.au/living-network/service-status"
                  >
                    Mobile network outages
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
export default Outages
